%view-term-filter {
  .form-type-bef-link {
    float: left;
    margin-bottom: em(5);

    a {
      display: inline-block;
      margin-right: em(10);
      padding: em(5) em(20);
      background-color: $white;
      text-decoration: none;
      color: $gray-600;

      &.active {
        background-color: $primary;
        color: $white;
      }

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}

%h2 {
  color: $gray-600;
  font-family: $open-sans;
  font-size: em(22);
  word-wrap: break-word;
}

%blue-link {
  color: $primary;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

%label-views {
  display: inline-block;
  float: left;
  margin-right: em(10);
  width: auto;
  padding-top: em(7);
  font-weight: normal;
}

%fa {
  display: inline-block;
  font-family: FontAwesome;
  font-feature-settings: normal;
  font-kerning: auto;
  font-language-override: normal;
  font-size: inherit;
  font-size-adjust: none;
  font-stretch: normal;
  font-style: normal;
  font-synthesis: weight style;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-rendering: auto;
  color: $gray-600;
  margin-left: em(5);
}

%sidebar {
  a {
    color: $gray-600;
    text-decoration: underline;
  }

  ul {
    padding-left: 0;

    li {
      list-style: none;

      &:before {
        color: $gray-600;
        content: "\f105";
        font-family: 'FontAwesome';
        padding-right: 5px;
      }
    }

  }

  ol {
    padding-left: 15px;
  }

  h2, h3, h4 {
    color: $primary;
    font-family: $open-sans;
    font-weight: 600;
    margin-top: 0;
    text-transform: uppercase;
  }

  h2 {
    font-size: em(14);
  }

  h3 {
    font-size: em(12);
  }

  h4 {
    font-size: em(10);
  }

}

%list-style {
  ul {
    padding-left: 25px;

    li {
      list-style: none;
      padding-left: 15px;
      position: relative;
      list-style: none;

      &:before {
        content: "\f10c";
        font-family: 'FontAwesome';
        font-size: 9px;
        position: absolute;
        left: 0;
        top: 4px;
      }

    }
  }
}
