@mixin whiteSpaceNormal {
  white-space: normal;
}

@mixin faw-icon($fa-icon,$size:16,$weigth: 900) {
  @include fa-icon;
  content: fa-content($fa-icon);
  font-size: rem($size);
  font-weight: $weigth;
}
