table.dataTable {
  thead > tr {
    > th {
      &.sorting {
        padding-right: 30px;
      }
    }

    > th,
    > td {
      &.sorting {
        &:before,
        &:after {
          top: auto;
          bottom: 15px;
        }

        &:before {
          @include faw-icon($fa-var-long-arrow-down, 14);
          right: 18px;
        }

        &:after {
          @include faw-icon($fa-var-long-arrow-up, 14);
        }
      }

      &.sorting_asc,
      &.sorting_desc {
        &:before {
          content: none;
        }

        &:after {
          opacity: .5;
        }
      }

      &.sorting_asc:after {
        @include faw-icon($fa-var-sort-amount-asc, 14);
      }

      &.sorting_desc:after {
        @include faw-icon($fa-var-sort-amount-desc, 14);
      }
    }
  }
}

.table th, .table td {
  padding: .5rem;

  ul {
    margin: 0;
    padding-left: 1em;
  }
}
