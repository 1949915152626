#header {
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar {
    padding: $navbar-padding-y 0;

    .navbar-toggler {
      border: 0;
    }

    @include media-breakpoint-up(md) {
      align-items: flex-start;
      flex-direction: column;
      .language-switcher-locale-url {
        li {
          display: inline-block;
          font-size: 12px;

          & + li {
            border-left: 1px solid $gray-300;
            margin-left: 8px;
            padding-left: 8px;
          }

          a {
            display: block;
            text-decoration: none;
          }

          &.active a {
            color: $primary;
          }
        }
      }
    }

    .navbar-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: $navbar-padding-y $navbar-padding-x $navbar-padding-y*2;
      @include media-breakpoint-up(md) {
        padding: 0;
      }
    }
  }

  #menuDwh {
    width: 100%;

    .language-switcher-locale-url {
      margin: 0;

      li {
        background-color: $gray-200;
        border-top: 1px solid $white;

        a {
          display: block;
          padding: 10px 0 10px 15px;
          text-transform: uppercase;
        }
      }
    }
  }

  .main-menu {
    width: 100%;

    div[class^='col-'] {
      .category {
        display: flex;
        justify-content: space-between;
        background-color: $primary;
        border-bottom: 1px solid $white;
        color: $white;
        padding: 12.5px 15px;
        font-family: $open-sans;
        font-size: em(14);
        font-weight: 400;
        text-decoration: none;

        .fa {
          font-size: 1.2em;
          margin-left: .5em;
        }
      }

      .menu-children {
        background-color: $white;
        border: 1px solid $gray-300;
        font-family: $arial;

        li a {
          display: block;
          padding: 10px 15px;
          color: $gray-900;
        }
      }

      @include media-breakpoint-up(lg) {
        &:not(:first-child) {
          .category {
            border-left: 1px solid $white;
          }
        }
        .category {
          font-size: em(18);
        }
        a.category {
          height: 100%;
        }
        .menu-wrapper {
          position: absolute;
          z-index: $zindex-dropdown;
          width: 100%;
        }
        .menu-children {
          padding: 10px 0;

          li a {
            padding: 3px 15px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    #home-menu div[class^='col-']:not(:first-child) {
      .menu-children {
        border-left: 0;
      }
    }
  }
}
