.region-sidebar-second .col-md-10 {
  color: $gray-600;
  font-size: em(13);
  padding-top: 1em;

  .paddingBorder {
    border-bottom: 1px solid $gray-300;
    padding-bottom: 1em;
    @extend %sidebar;

    .field {
      border-top: 1px solid $gray-300;
      margin-top: em(20);
      padding-top: 1em;
    }
  }
}

aside.col-sm-3 {
  color: $gray-600;
  font-size: em(13);
  padding-top: 1em;
  border-bottom: 1px solid $gray-300;
  padding-bottom: 1em;
  @extend %sidebar;
}

@include media-breakpoint-up(md) {
  .col-sm-3 {
    .region.region-sidebar-second {
      margin-left: -15px;
    }
  }
}

.menu-04 {
  .region-sidebar-second {
    .paddingBorder {
      h2, h3, h4 {
        color: $green;
      }
    }
  }
}
