/*================================================================ General*/
body {
  background-color: $gray-900;
  color: $gray-900;
  font-family: $arial;
  font-weight: normal;
  margin-bottom: em(160); /*sticky footer*/
  &.sonata-bc {
    background-color: inherit;
  }
  &.cke_editable, &.wysiwyg-preview, &.cke_ltr {
     background-color: white;
     padding: 5px;
     margin-bottom: 0;
   }
}

.main-container {
  background: $white;
  padding-bottom: 2em;
}

/*================================================================ Typography*/
h1 {
  color: $primary;
  font-family: $open-sans;
  font-size: em(32, 14);
  line-height: 130%;
}

h2 {
  @extend %h2;
  margin-bottom: em(20, 22);
  color: $primary;
}

.article-body {
  h4 {
    margin-top: 2em;
  }
}

.page-node .region-content .field-name-body h2 {
  margin: em(40, 22) 0 em(20, 22) 0;

  &:first-child {
    margin-top: 0;
  }
}

h3 {
  color: $primary;
  font-family: $open-sans;
  font-size: em(18, 14);
  font-weight: 600;
}

/* Links color */
.region-content {
  a {
    color: $gray-900;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.bg-grey {
  background-color: $gray-200;
  margin-bottom: em(40);
  .breadcrumb, .breadcrumb a {
    color: darken($gray-600, 5);
  }
}

.container-fluid.bg-grey {
  margin-bottom: em(40);
  padding-bottom: em(65);
}

.btn-primary, .btn-info {
  background-color: $primary;
  border-color: transparent;
}


/**
* pagination
**********************************/
.pagination .progress-disabled {
  float: left;
}

.pagination > li > a, .pagination > li > span {
  border-radius: em(4);
  font-size: em(12);
}

.pagination > li.active > a, .pagination > li.active > span {
  color: $white !important;
}

.pagination > li {
  display: inline-block;
  margin-right: em(5);
}

/**
* tabs
*************************************/
.tab-content {
  margin-top: em(10);
}
