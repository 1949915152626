/*Header effect*/
@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.slideDown {
  animation: slideDown 0.5s both;
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.slideUp {
  animation: slideUp 0.5s both;
}
