footer {
    background:$gray-900;
    color:$white;
    font-size:12px;
    height:auto;
    padding-bottom:2em;
    padding-top: 2.083em;
    width:100%;
    a {
        color:$white;
        text-decoration:underline;
        &:hover {
            color:$white;
            text-decoration:none;
        }
    }
    .nav li {
        padding-bottom:em(5);
        & > a {
            padding:0;
            text-decoration:none;
        }
        & > a:hover, & > a:focus {
            background-color: $noColor;
            text-decoration:underline;
        }
        @include media-breakpoint-up(sm) {
            &:nth-child(4n) {
                padding-bottom:0;
            }
        }
        @include media-breakpoint-down(xs) {
            padding-bottom:em(10);
        }
    }
}
