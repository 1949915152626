/*Language override*/
.language_selection_page_body {
  background: none;
  font-family: "Open sans", Arial, Helvetica, sans-serif;
  height: auto;
  margin-top: 0;
  padding-top: 0;
  width: map-get($grid-breakpoints, "xs");
  max-width: map-get($grid-breakpoints, "lg");

  [class*='col-sm-'] {
    a {
      border-bottom: 1px solid $gray-300;
      color: $primary;
      display: block;
      font-size: 18px;
      padding: em(10) 0;

      div {
        height: em(45);
        margin-bottom: 0;
        overflow: hidden;
        text-indent: -9999px;
      }

      .logo-nl {
        background: url("/static/logos/language-nl.jpg") 0 0 no-repeat;
      }

      .logo-fr {
        background: url("/static/logos/language-fr.jpg") 0 0 no-repeat;
      }

      .logo-en {
        background: url("/static/logos/language-en.jpg") 0 0 no-repeat;
      }
    }
  }

  .belgium {
    a {
      background: url("/static/logos/belgium-gray.png") 0 0 no-repeat;
      border: 0;
      float: right;
      height: 22px;
      padding: 0;
      margin-bottom: 1em;
      margin-top: em(27);
      overflow: hidden;
      text-indent: -9999px;
      width: 31px;
    }
  }
}

@include media-breakpoint-up(xs) {
  .language_selection_page_body {
    width: map-get($grid-breakpoints, "xs");
  }
}

@include media-breakpoint-up(sm) {
  .language_selection_page_body {
    margin-top: 8em;
    padding-top: 3em;
    width: map-get($grid-breakpoints, "sm");

    [class*='col-sm-'] {
      a {
        border-top: 1px solid $gray-300;
        min-height: 15.35em;
        padding: 58px 0;

        div {
          margin-bottom: em(32);
        }
      }
    }

    .belgium {
      a {
        border: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .language_selection_page_body {
    width: map-get($grid-breakpoints, "lg");
  }
}

/**
* back to top
*********************************/
@include media-breakpoint-down(md) {
  #back-top {
    display: none !important
  }
}

/**
* datatable
************************************/
div.alphabet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1em;

  .btn {
    color: darken($gray-300, 20%);
    text-align: center;
    padding: 0 em(3);
    &:not(:disabled) {
      color: darken($primary, 4);
    }

    &.active {
      color: $black;
    }
  }

  @include media-breakpoint-down(sm) {
    display: block;
    padding: em(10) em(5);
    .btn {
      padding: em(5) em(8);
    }
  }
}

form#filters {
  margin-bottom: 15px;
  .filter-wrapper {
    display: flex;
    flex-direction: column;
    .control-label {
      font-weight: bold;
    }
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
      .form-group {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        margin-right: .5em;
      }
      .control-label {
        flex-shrink: 0;
        margin-bottom: 0;
        margin-right: .5em;
      }
      .form-control {
      }
    }
  }
  /*label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    margin-right: 5px;
    font-weight: 700;
  }*/

  /*@include media-breakpoint-up(xl) {


    & .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
  }*/
}


.datepicker {
  td, th {
    cursor: pointer;
  }
}
