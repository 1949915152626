/* Fonts */
$open-sans: 'Open Sans',Impact,Verdana,Geneva,sans-serif;
$arial: Arial,Verdana,Geneva,sans-serif;

/* Colors */
$noColor: transparent;
$white: #fff;          // White
$gray-200: #ececec;       // TH
$gray-300: #dadada;       // Light Gray : Bg, sideBar
$gray-600: #767676;       // Gray : Links headers, pipe footer, breadcrumb
$gray-900: #2e3031;       // Text, footer, breadcrumb active

$blue: #007eb0;       // Blue : Title, link
$blue-dark: #036d96;       // Blue mobile
$green: #8cae22;       // Green    About
$yellow: #e4dc71;       // Alert

$primary: $blue;
$btn-info-bg:      $primary;

$font-size-base:              .875rem;
$font-family-sans-serif:      $open-sans, Roboto, "Helvetica Neue", Arial !default;
$headings-font-weight:        600;

$navbar-toggler-padding-y:          0;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          1.75em;
$navbar-toggler-border-radius:      0;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);

$link-color:                        $gray-600;
$pagination-color:                  darken($link-color, 4);
$pagination-bg:                     lighten($gray-200,1);
$pagination-border-color:           lighten($gray-200,1);

$pagination-active-color:           $white;
$pagination-active-bg:              $primary;
$pagination-active-border-color:    $primary;

$admin-menu-font-color: $white;