.description {
	background-color:$gray-300;
	margin:em(10) 0;
	padding:em(10) em(15);
	border-right:1px solid $white;
	@include media-breakpoint-down(sm) {
		border-right:0;
	}
}
#partners li {
	margin: 0 10px 10px 0;
}
