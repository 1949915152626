body.page-language-selection {
  background: $white;
}

/**
* General
***************************************/
.breadcrumb {
  background-color: $noColor;
  border-radius: 0;
  font-size: em(12, 14);
  list-style: outside none none;
  margin-bottom: em(20, 12);
  padding: em(6, 12) 0;
  color: $gray-600;

  a {
    text-decoration: underline;
  }

  & > li + li::before {
    color: $gray-600;
  }

  & > .active, .last {
    color: $gray-900;
  }
}

.region-content {
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.main-container, .wysiwyg-preview {
  #page-header {
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: 170%;
    height: 72px;
    margin-top: 3.6em;
    @include media-breakpoint-up(md) {
      background-size: cover;
      height: 139px;
      margin-top: 0em;
    }
  }

  h1 {
    border-bottom: 0;
    margin: 0 0 30px 0;
    padding-bottom: 0;
  }

  @include media-breakpoint-up(md) {
    .headerTitle {
      white-space: nowrap;
    }
    .headerTitleChild {
      display: inline-block;
    }
  }

  .field-name-field-intro-before-toc {
    margin: em(15) 0;
  }

  .attachment, #toc {
    margin-bottom: em(50);

    ul {
      padding-left: 3px;

      li {
        list-style: none;
        margin-bottom: .2em;
        padding-left: 10px;

        &:before {
          color: $primary;
          content: "\f105";
          font-family: 'FontAwesome';
          font-size: 11px;
        }

        a {
          color: $primary;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  #toc {
    ul {
      padding-left: 0;
    }
  }
}

/*
* variablesBySource
***********************************************************/
ul.list-columns-blocks {
  padding-left: 0;
  list-style-type: none;
  @include media-breakpoint-up(lg) {
    column-count: 2;
  }

  li.list-column-item {
    display: block;
    break-inside: avoid;
    padding: 15px 15px 15px 0;

    a .fa {
      color: $gray-600;
    }

    > ul {
      padding-left: 0;
      margin-top: 0;

      & > li {
        padding-left: 15px;
        position: relative;
        list-style: none;

        &:before {
          color: $primary;
          content: "\f10c";
          font-family: 'FontAwesome';
          font-size: 9px;
          position: absolute;
          left: 0;
          top: 3px;
        }
      }
    }
  }
}

ul.list-columns {
  @include media-breakpoint-up(lg) {
    column-count: 2;
  }
}


#table-filtered_wrapper {
  @include media-breakpoint-down(sm) {
    #table-filtered_length {
      margin-top: em(10);
    }
    #table-filtered_info, #table-filtered_paginate {
      margin-bottom: em(10);
    }
  }
}


/*Basic page*/
#page-header {
  background-image: url("/static/banner-default.png");
  background-size: cover;
  position: absolute;
  top: 116px;
  width: 100%;
  height: 139px;
  @include media-breakpoint-down(xs) {
    background-size: 170% auto;
    height: 72px;
  }
}

#wrapper-top-page {
  background-color: $white;
}

img.image-institution {
  height: 100%;
  max-height: 70px;
}
